<template>
  <div
    style="
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
    "
  >
    <h1 style="font-size: 24px; width: 100%; text-align: center">Объявления</h1>

    <div
      style="
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
      "
    >
      <div
        v-for="(post, index) in posts"
        :key="index"
        id="post"
        style="display: flex; align-items: baseline"
      >
        <div v-if="!post.privated" style="width: 100%; border-radius: 10px">
          <div
            style="
              font-size: 17px;
              width: auto;
              font-weight: bold;
              padding-left: 10px;
              background-color: darkblue;
              border-radius: 10px;
              border-bottom-left-radius: 0px 0px;
              border-bottom-right-radius: 0px 0px;
            "
          >
            {{ post.title }}
          </div>

          <div style="font-size: 14px; font-weight: normal; padding-left: 15px">
            {{ post.text }} <br />
            <a
              style="color: aqua"
              v-if="post.link"
              target="_blank"
              :href="post.link"
              >Ссылка--></a
            >
            <br />
            <div style="float: right; padding-right: 10px">
              {{ new Date(post.createdAt).toLocaleDateString() }}
            </div>
          </div>
        </div>
        <div v-else id="private" style="width: 100%; border-radius: 10px">
          <div
            style="
              font-size: 17px;
              width: auto;
              font-weight: bold;
              padding-left: 10px;
              background-color: darkblue;
              border-radius: 10px;
              border-bottom-left-radius: 0px 0px;
              border-bottom-right-radius: 0px 0px;
            "
          >
            {{ post.title }}
          </div>

          <div style="font-size: 14px; font-weight: normal; padding-left: 15px">
            Объявление содержит приватные данные. Для просмотра необходимо войти
            в систему и перейти на вкладку Объявления
          </div>
          <div style="float: right; padding-right: 10px; font-size: 14px">
            {{ new Date(post.createdAt).toLocaleDateString() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";
export default {
  data() {
    return {
      posts: [],
    };
  },
  methods: {
    async getAD() {
      await TutorialDataService.getAD()
        .then((response) => {
          var a = new Array();
          a = Object.values(response.data);

          this.posts = a;

          this.posts.sort((a, b) =>
            new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  async mounted() {
    await this.getAD();
  },
};
</script>

<style lang="scss" scoped>
#post {
  background-color: rgba(36, 42, 95, 0.558);
  width: 75%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 10px;
}
#private {
  background-color: rgba(95, 36, 36, 0.558);
}
</style>
